import React from 'react'
import Layout from '../../components/layout'
import { ProjectHeader } from '../../components/projects'
import ProjectCTA from '../../components/project-cta'
import Image from '../../images/projects/project-main-workplace-one.jpg'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import './arcurve.less'

class WorkplaceOne extends React.Component {
  render() {
    return (
      <Layout>
        <ProjectHeader
          title="WorkplaceOne"
          year="2020"
          index="1"
          image={Image}
          total="3"
          overview="Dedicated to providing the ultimate in flexibility, Workplace One has a solution for your business needs. Whether it is a versatile community membership, a private office for teams of 2 to 50, or a standalone, serviced HQ Suite, the choice is yours."
          expertise="Design and Development"
          url="https://workplaceone.com"
          prev="/featured-projects/everyday-icing"
          next="/featured-projects/eras"
        />

        <section className="project-single-body arcurve" id="start_dark_menu">
          <div className="uk-container">
            <div className="uk-grid">
              <div className="uk-width-1-2@xl uk-width-2-3@m uk-width-5-6@s margin uk-margin-auto">
                <h2>Enso's work</h2>
                <p>
                  When Workplace One first engaged Worksimply, there was a
                  disconnect between their website experience and their
                  workspace experience. In other words, when you walk into any
                  Workplace One location, you can see that their spaces are
                  beautiful designed, spacious and built for productivity in
                  mind, however, the old website didn't live up to the
                  expectations of the workspaces.
                  <br />
                  <br />
                  Not only did we work with Workplace One to redesign their
                  website but we also integrated a Space Operator software
                  called Worksimply Pro. Operators use Worksimply Pro to set up,
                  and manage their office space locations and their availability
                  across multiple sales channels. Essentially it's a modern
                  listing platform that helps operators qualify leads better.
                </p>
              </div>
            </div>

            <div className="uk-grid">
              <div className="uk-width-5-6@s uk-margin-auto">
                <img
                  src={require('../../images/projects/workplace-one/wp1-1.jpg')}
                  alt=""
                  className="shadow image-margin-bottom"
                />
              </div>
            </div>

            <div className="uk-grid margin">
              <div className="uk-width-5-6@s uk-margin-auto">
                <img
                  src={require('../../images/projects/workplace-one/wp1-2.jpg')}
                  alt=""
                  className="shadow image-margin-bottom"
                />
              </div>
            </div>

            <div className="uk-grid">
              <div className="uk-width-5-6@s uk-margin-auto">
                <img
                  src={require('../../images/projects/workplace-one/wp1-3.jpg')}
                  alt=""
                  className="shadow image-margin-bottom"
                />
              </div>
            </div>
          </div>
        </section>

        <ProjectCTA link="eras" title="ERAS" />
      </Layout>
    )
  }
}

export default WorkplaceOne

export const query = graphql`
  query {
    projectImage1: file(
      relativePath: { eq: "projects/workplace-one/wp1-1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
